import { __decorate } from "tslib";
import { Component, Vue, Prop } from 'vue-property-decorator';
import { WorkPlatformService } from '@/sevices/index';
import { namespace } from 'vuex-class';
const workPlatformModule = namespace('workPlatform');
let ComponentName = class ComponentName extends Vue {
    clickHandler(item) {
        // htttp://xxxxx/#/appCode/appVersion?groupCode=1&moduleCode=1&pageGroupCode=1&pageCode=1
        const params = {
            appCode: item.appCode,
            appVersion: item.version,
        };
        // const winOpen: any = window.open('', '_blank');
        WorkPlatformService.getAppUrl(params).then((res) => {
            if (res.success) {
                const homePage = item.homepages || {};
                const query = {
                    groupCode: '',
                    pageGroupCode: '',
                    pageCode: '',
                    ...homePage,
                    appVersion: item.version,
                    workflowCode: item.workflowCode,
                };
                const str = Object.entries(query)
                    .map((queryArr) => {
                    return queryArr.join('=');
                })
                    .join('&');
                const url = `${res.data}?${str}`;
                this.setRecentApp(item.id);
                // window.location.href = url;
                try {
                    // if (!winOpen) {
                    //   throw new Error('浏览器新开弹窗未开启');
                    // }
                    setTimeout(() => {
                        window.location.href = url;
                    }, 100);
                }
                catch (e) {
                    Vue.prototype.$errorcatcher.showMessagePopup({
                        title: '错误提示！',
                        message: [e, '!'],
                        displayFooter: false,
                        correctGuide: '请认真检查浏览器是否开启禁止新开弹窗！',
                    });
                }
            }
        });
    }
    setRecentApp(id) {
        const ids = this.recentIds;
        let idsArr = [];
        if (Array.isArray(ids)) {
            idsArr = ids.filter((item) => item !== id);
        }
        idsArr.unshift(id);
        this.setRecentIds(idsArr);
    }
};
__decorate([
    Prop({ default: [] })
], ComponentName.prototype, "details", void 0);
__decorate([
    workPlatformModule.State('recentIds')
], ComponentName.prototype, "recentIds", void 0);
__decorate([
    workPlatformModule.Action('setRecentIds')
], ComponentName.prototype, "setRecentIds", void 0);
__decorate([
    workPlatformModule.Action('setOnlineApps')
], ComponentName.prototype, "setOnlineApps", void 0);
ComponentName = __decorate([
    Component({
        name: 'app-item',
        components: {
            AppImage: () => import('./app-image.vue'),
        },
    })
], ComponentName);
export default ComponentName;
